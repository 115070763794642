import { useState, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/compat/router';
import { useRouter as useRouterNavigation } from 'next/navigation';
import { nanoid } from 'nanoid';
import Icon from '@/core/icon';
import { lang, useTranslation } from '@/helpers/translate';
import { getPathByLang, switchPageByLang } from '@/helpers/router.js';
import { Select } from '@/core/dynamic';
import Guide from '@/components/header/cities.js';
import Cities from '@/components/header/guide.js';
import { useZusUserConnectedStore } from '@/helpers/user-auth.js';
import {
  useSpacePublicZustandStore,
} from '@/helpers/spacepublicprovider';

function SwitchLangs() {
  const { comefrom } = useZusUserConnectedStore.getState();
  const { t } = useTranslation();
  const langsTrad = t('core:lang', {}, { returnObjects: true });
  const langCurrent = lang();
  const { locale, asPath, ...routerData } = useRouter() || {};

  const spacePublicUrls = useSpacePublicZustandStore((state) => state.spacePublicUrls);

  const [langs, setLangs] = useState(Object.entries(langsTrad || {}).map(([value, title]) => ({
    value,
    title,
    content: <Link key={value} className="block px-4 py-2" href={switchPageByLang(asPath, locale, value)} passHref locale={value}>{title}</Link>,
    contentSelected: <span key={value} className="block px-4 py-2 text-base">{title}</span>,
  })));

  useEffect(() => {
    if (comefrom === 'space-public' && spacePublicUrls) {
      setLangs(Object.entries(langsTrad || {}).map(([value, title]) => ({
        value,
        // content: <Link key={`footermultilangspacePublicUrls${value}`} href={`${process.env.NEXT_PUBLIC_URL_V4}${spacePublicUrls[value]}`} passHref locale={value}><a className="block px-4 py-2">{title}</a></Link>,
        content: <span className="block px-4 py-2">{title}</span>,
        contentSelected: <span className="block px-4 py-2 text-base">{title}</span>,
        url: `${process.env.NEXT_PUBLIC_URL_V4}${spacePublicUrls[value]}`,
      })));
    }
  }, [spacePublicUrls]);

  return (
    <div className="pt-6 pb-8 md:pb-10">
      <label htmlFor="lang" className="flex flex-col pb-2 text-lg font-medium">{t('footer:language')}</label>
      <Select
        options={langs}
        valueDefault={langCurrent}
        onSelected={(value) => {
          // MI-871 space-public need refresh url to load data from server for new language
          if (comefrom === 'space-public' && value?.url) {
            window.location.href = value?.url;
          }
        }
      }
      />
    </div>
  );
}

export default function Footer() {
  const { t } = useTranslation();
  const langCurrent = lang();
  const router = useRouterNavigation();

  return (
    <footer className="container px-6 py-10 mx-auto border-t border-white-dark2 md:border-none lg:px-28 md:py-10 md:grid md:grid-cols-3 xl:grid-cols-4 md:gap-4">
      <div className="flex flex-col justify-start text-sm md:pr-14">
        <a aria-label="homepage" href="/"><Icon name="logo-officeriders-blue" className="w-24 h-24 mb-4 text-black fill-current" /></a>
        <SwitchLangs key={nanoid()} />
      </div>
      <div className="pb-4">
        <ul className="pb-4 text-base sm:hidden text-dark">
          <li><button className="pb-2" onClick={() => { window?.HubSpotConversations?.widget?.open(); }}>{t('header:need_help')}</button></li>
          <li className="pb-2"><Guide /></li>
          <li className="pb-2"><Cities /></li>
        </ul>
        <div className="pb-2 text-lg font-medium">{t('footer:discover.discover')}</div>
        <ul className="text-base text-dark">
          <li className="pb-2"><Link href={getPathByLang('howto-ride', langCurrent)}>{t('footer:discover.howride')}</Link></li>
          <li className="pb-2"><Link href={getPathByLang('corporate-membership', langCurrent)}>{t('footer:discover.workstars')}</Link></li>
          <li className="pb-2"><Link href={getPathByLang('become-host', langCurrent)}>{t('footer:discover.becomehost')}</Link></li>
          <li className="pb-2"><Link href={getPathByLang('services', langCurrent)}>{t('footer:discover.services')}</Link></li>
          <li className="pb-2"><Link href={getPathByLang('insurance', langCurrent)}>{t('footer:discover.insurance')}</Link></li>
          <li className="pb-2"><a rel="nofollow" href="https://help.officeriders.com/">{t('footer:discover.help')}</a></li>
          <li className="pb-2 cursor-pointer"><span onClick={() => router.push(getPathByLang('sponsorship', langCurrent))}>{t('footer:discover.sponsorship')}</span></li>
          <li className="pb-2 cursor-pointer"><span onClick={() => window.open('https://officeriders.typeform.com/to/pagsMA', '_blank')}>{t('footer:discover.becomepartner')}</span></li>
        </ul>
      </div>
      <div className="pb-4">
        <div className="pb-2 text-lg font-medium">{t('footer:society.society')}</div>
        <ul className="text-base text-dark">
          {langCurrent !== 'fr' ? null : <li className="pb-2"><a href="/blog" target="_blank" rel="noreferrer">{t('footer:society.blog')}</a></li>}
          <li className="pb-2 cursor-pointer"><span onClick={() => router.push(getPathByLang('press', langCurrent))}>{t('footer:society.press')}</span></li>
          <li className="pb-2"><a href="https://partners.officeriders.com" target="_blank" rel="noreferrer">{t('footer:society.partners')}</a></li>
          <li className="pb-2 cursor-pointer"><span onClick={() => window.open('https://www.welcometothejungle.com/fr/companies/officeriders', '_blank')}>{t('footer:society.jobs')}</span></li>
          <li className="pb-2 cursor-pointer"><span onClick={() => router.push(getPathByLang('contact', langCurrent))}>{t('footer:society.contact')}</span></li>
          <li className="pb-2 cursor-pointer"><span onClick={() => router.push(getPathByLang('terms', langCurrent))}>{t('footer:society.terms')}</span></li>
        </ul>
      </div>
      <div className="pb-16 mb-10 md:mb-0 md:pb-10">
        <div className="pt-8 pb-2 text-lg font-medium md:pt-0">{t('footer:followus')}</div>
        <div className="flex">
          <span className="cursor-pointer" onClick={() => window.open('https://www.x.com/officeriders/', '_blank')}>
            <Icon name="logo-x" className="w-5 h-5 mr-4 fill-current text-dark-light hover:text-blue" width="40" height="32" />
          </span>
          <span className="cursor-pointer" onClick={() => window.open('https://www.linkedin.com/company/officeriders/', '_blank')}>
            <Icon name="logo-linkedin" className="w-5 h-5 mr-4 fill-current text-dark-light hover:text-blue" width="16" height="16" />
          </span>
          <span className="cursor-pointer" onClick={() => window.open('https://www.instagram.com/officeriders/', '_blank')}>
            <Icon name="logo-instagram" className="w-5 h-5 fill-current text-dark-light hover:text-blue" width="20" height="20" />
          </span>
        </div>
      </div>
    </footer>
  );
}
